export const FUNNELS: Record<
  'main' | 'cortisol' | 'pcos' | 'inflammation' | 'report' | 'challenge' | 'cortisol-bf' | 'generic',
  'main' | 'cortisol' | 'pcos' | 'inflammation' | 'report' | 'challenge' | 'cortisol-bf' | 'generic'
> = {
  main: 'main',
  cortisol: 'cortisol',
  pcos: 'pcos',
  inflammation: 'inflammation',
  report: 'report',
  challenge: 'challenge',
  'cortisol-bf': 'cortisol-bf',
  generic: 'generic',
};
