var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a14dcb5076f8d7d23c7792c685224beeed8e2e73"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

const IGNORE_ERRORS = [
  'Warning',
  'Hotjar not launching due to suspicious userAgent',
  "Can't fire event",
  'Hydration failed because the initial UI does not match what was rendered on the server.',
  'There was an error while hydrating.',
  "Can't find variable: _AutofillCallbackHandler",
  'Abort fetching component for route',
  'InvalidStateError',
  'Invariant: attempted to hard navigate to the same URL',
  'This Suspense boundary received an update before it finished hydrating.',
  'Unsupported prop change on Elements: You cannot change the `stripe` prop after setting it.',
];

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 0.1,
  ignoreErrors: IGNORE_ERRORS,
});
